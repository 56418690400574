import React from 'react'
import Maillist from '../components/Maillist'
const Userdms = () => {
  return (
    <div>
        <Maillist />
    </div>
  )
}

export default Userdms