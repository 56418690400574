import React, { useState } from "react";
import Example from "../assets/sample2.png";
import { DynamicWidget } from "@dynamic-labs/sdk-react-core";
import Stats from "../assets/stats.png";
import { GiDiamondsSmile } from "react-icons/gi";
import { CiChat1 } from "react-icons/ci";
import ReactModal from "react-modal";
import Typewriter from "typewriter-effect";
import { CardBody, CardContainer, CardItem } from "../components/ui/3d-card";

function Home() {
  const [show, setShow] = useState(false);
  const [willCLose, setWillClose] = useState(true);
  return (
    <div className="py-12 overflow-y-hidden">
      {/* <ReactModal className="flex items-center justify-center h-[100vh] flex-col text-center" isOpen={willCLose}>
        <h1 className="text-2xl">
          DevMatch is under maintenance. Please onboard yourself with the connect your wallet button (you don't need a wallet, just your github account) and we will email you once we are back! .
          <p>
          we are sorry for the inconvenience but hey, we are working on something cool!
          </p>
          <button onClick={() => setWillClose(false)} className="bg-gray-700 rounded-md px-2 py-1 mt-4 text-white hover:text-indigo-900">Close</button>
        </h1>
      </ReactModal> */}
      <div className="min-h-[1000px]  flex items-center justify-center">
        <dh-component>
          <div className="w-full px-6">
            <div className="mt-[-50px] md:mt-10 sm:mt-10 relative rounded-lg bg-indigo-700 container mx-auto flex flex-col items-center pt-12 sm:pt-24 pb-24 sm:pb-32 md:pb-48 lg:pb-56 xl:pb-64">
              <img className="mr-2 lg:mr-12 mt-2 lg:mt-12 absolute right-0 top-0" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg2.svg" alt="bg" />
              <img className="ml-2 lg:ml-12 mb-2 lg:mb-12 absolute bottom-0 left-0" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg3.svg" alt="bg" />
              <div className="w-11/12 sm:w-2/3 mb-5 sm:mb-10">
                <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-white font-bold leading-tight">A decentralized platform to find your
                <Typewriter
              style={{ backgroundColor: "transparent" }}
              className="bg-transparent"
              onInit={(typewriter) => {
                typewriter
                  .typeString("Co-Founder!")
                  .callFunction(() => {
                    console.log("String typed out!");
                  })
                  .pauseFor(2500)
                  .deleteAll()
                  .typeString(
                    "Tech-Mate!"
                  )
                  .pauseFor(2500)
                  .deleteAll()
                  .typeString("Mentor!")
                  .pauseFor(2500)
                  .deleteAll()
                  .start();
              }}
              options={{
                loop: true,
              }}
            />
                </h1>
                <p className="text-white text-center">(Fun-fact: You don't need a wallet, will generate one for you!)</p>
              </div>
              <div className="flex justify-center items-center mb-10 sm:mb-20">
                <button className="hover:text-white hover:bg-transparent lg:text-xl hover:border-white border transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ring-offset-indigo-700	focus:ring-white rounded text-indigo-700 text-sm"><DynamicWidget /></button>
                {/* <button className="hover:bg-white hover:text-indigo-600 lg:text-xl hover:border-indigo-600 ml-3 sm:ml-6 bg-transparent transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ring-offset-indigo-700 focus:ring-white hover:bg-indigo-700-800 rounded border border-white text-white px-4 sm:px-8 py-1 sm:py-2 text-sm">Learn More</button> */}
              </div>
            </div>
            <div className="container mx-auto flex justify-center md:-mt-80 -mt-40 sm:-mt-50">
              <div className="relative sm:w-2/3 w-11/12">
                {/* <img src={Example} alt="Sample Page" className="rounded-md" role="img" /> */}
                <CardContainer className="inter-var">
      <CardBody className="bg-gray-50 relative group/card  dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-black dark:border-white/[0.2] border-black/[0.1] w-auto md:w-[40rem] sm:w-[35rem] h-auto rounded-xl p-6 border  ">
        <CardItem
          translateZ="50"
          className="text-xl font-bold text-neutral-600 dark:text-white"
        >
          Create your dev profile
        </CardItem>
        <CardItem
          as="p"
          translateZ="60"
          className="text-neutral-500 text-sm max-w-sm mt-2 dark:text-neutral-300"
        >
          Show your rizumé to the world and get connected with developers from 21+ countries.
        </CardItem>
        <CardItem
          translateZ="100"
          rotateX={20}
          rotateZ={-10}
          className="w-full mt-4"
        >
          <img
          src={Example}
            // height="1000"
            // width="1000"
            className="h-full w-full object-cover rounded-xl group-hover/card:shadow-xl"
            alt="thumbnail"
          />
        </CardItem>
        {/* <div className="flex justify-between items-center mt-20">
          <CardItem
            translateZ={20}
            translateX={-40}
            as="button"
            className="px-4 py-2 rounded-xl text-xs font-normal dark:text-white"
          >
            Try now →
          </CardItem>
          <CardItem
            translateZ={20}
            translateX={40}
            as="button"
            className="px-4 py-2 rounded-xl bg-black dark:bg-white dark:text-black text-white text-xs font-bold"
          >
            Sign up
          </CardItem>
        </div> */}
      </CardBody>
    </CardContainer>
              </div>
            </div>
          </div>
        </dh-component>
      </div>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <div class="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
            <img alt="feature" class="object-cover object-center h-full w-full" src={Stats} />
          </div>
          <div class="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
            <div class="flex flex-col mb-10 lg:items-start items-center">
              <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                </svg>
              </div>
              <div class="flex-grow">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Reach global</h2>
                <p class="leading-relaxed text-base">Showcase your profile and get connected with developers on a global level <br />Join developers form 21+ countries</p>
              </div>
            </div>
            <div class="flex flex-col mb-10 lg:items-start items-center">
              <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5">
                <GiDiamondsSmile className="text-2xl" />
              </div>
              <div class="flex-grow">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Connect with those whom you want to.</h2>
                <p class="leading-relaxed text-base">Like, Dislike and get connected with those whom you are matched with!</p>
              </div>
            </div>
            <div class="flex flex-col mb-10 lg:items-start items-center">
              <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5">
               <CiChat1 className="text-2xl"/>
              </div>
              <div class="flex-grow">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-3">Chat</h2>
                <p class="leading-relaxed text-base">Communicate with those you're matched with by using push protocol's end-to-end communication protocol.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  );
}

export default Home;
